import { useEffect, useState } from "react";
import { Form, NavLink, NavLinkProps, useFetcher } from "@remix-run/react";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Separator,
  // Popover,
  Theme,
} from "@radix-ui/themes";
import { Fragment } from "react/jsx-runtime";
import * as Popover from "@radix-ui/react-popover";
import { Cross2Icon, HamburgerMenuIcon } from "@radix-ui/react-icons";
import { cn } from "../lib/utils";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./dialog";
import { useToast } from "./toast";
import logo from "~/images/logo.png";

export type NavItemProp = NavLinkProps &
  React.RefAttributes<HTMLAnchorElement> & {
    to: string;
    icon?: React.ReactNode;
    label: string;
  };
function NavItem(props: NavItemProp) {
  const { to, icon, label, ...rest } = props;
  return (
    <NavLink
      to={to}
      {...rest}
      className={({ isActive }) =>
        `flex items-center p-4 ${isActive ? "bg-blue-100 text-blue-700" : "text-gray-700 hover:bg-gray-100"}`
      }
    >
      {icon && <Box mr="2">{icon}</Box>}
      {label}
    </NavLink>
  );
}

export function Nav({
  headingText,
  headingDescription,
  navItems,
  bottomPopoverItems,
  email,
  sendPasswordResetEmailActionPath,
  logoutActionPath,
}: {
  headingText: string;
  headingDescription?: string;
  navItems: NavItemProp[];
  bottomPopoverItems: NavItemProp[];
  email: string;
  sendPasswordResetEmailActionPath: string;
  logoutActionPath: string;
}) {
  return (
    <Box
      width="280px"
      style={{
        borderRight: "1px solid var(--gray-6)",
        height: "100vh",
        overflowY: "auto",
      }}
    >
      <Flex direction="column" gap="2" justify="between" height="100%">
        <Flex direction="column">
          <Flex p="4" direction="column">
            <Heading size="5">{headingText}</Heading>
            {headingDescription && (
              <Heading size="3">{headingDescription}</Heading>
            )}
          </Flex>
          <Separator size="4" />
          <Flex direction="column">
            {navItems.map((item, index) => (
              <Fragment key={item.to}>
                <NavItem {...item} />
                <Separator size="4" />
              </Fragment>
            ))}
          </Flex>
        </Flex>

        <Flex direction="column" gap="2">
          <Flex
            direction="column"
            // className="border-r border-gray-100 box-border"
          >
            {bottomPopoverItems.map((item, index) => (
              <Fragment key={item.to}>
                <NavItem to={item.to} icon={item.icon} label={item.label} />
                {index < bottomPopoverItems.length - 1 && (
                  <Separator size="4" />
                )}
              </Fragment>
            ))}

            <Separator size="4" />

            <SendPasswordResetEmailDialog
              sendPasswordResetEmailActionPath={
                sendPasswordResetEmailActionPath
              }
            />

            <Separator size="4" />
            <Form action={logoutActionPath} method="post" className="text-left">
              <button
                type="submit"
                className="w-full flex items-start text-left p-4 rounded text-gray-700 hover:bg-gray-100"
              >
                ログアウト
              </button>
            </Form>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}

function SendPasswordResetEmailDialog({
  sendPasswordResetEmailActionPath,
}: {
  sendPasswordResetEmailActionPath: string;
}) {
  const [open, setOpen] = useState(false);
  const fetcher = useFetcher<{ success: boolean }>();
  const { toast } = useToast();

  const handleSubmitSendPasswordResetEmail = (event) => {
    event.preventDefault();
    fetcher.submit(
      {},
      {
        method: "post",
        action: sendPasswordResetEmailActionPath,
      }
    );
  };

  useEffect(() => {
    if (fetcher.state === "idle" && fetcher.data?.success) {
      setOpen(false);
      toast({
        title: "再設定メールを送信しました",
        description: "メールのリンクからパスワードを再設定してください。",
        variant: "success",
      });
    }
  }, [fetcher.state]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <span className="flex items-center p-4 rounded text-gray-700 hover:bg-gray-100">
          パスワード再設定
        </span>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <Theme>
          <Flex direction="column" gap="4">
            <DialogHeader>
              <DialogTitle>パスワード再設定</DialogTitle>
              <DialogDescription>
                ログインパスワードの再設定リンクをメールで送信します。
              </DialogDescription>
            </DialogHeader>
            <DialogFooter className="justify-end">
              <fetcher.Form
                action={sendPasswordResetEmailActionPath}
                method="post"
                // className="text-left"
                onSubmit={handleSubmitSendPasswordResetEmail}
              >
                <Button type="submit" size="2">
                  送信する
                </Button>
              </fetcher.Form>
            </DialogFooter>
          </Flex>
        </Theme>
      </DialogContent>
    </Dialog>
  );
}

export function TwoColumnLayout({
  headingText,
  headingDescription,
  navItems,
  bottomPopoverItems,
  children,
  email,
  sendPasswordResetEmailActionPath,
  logoutActionPath,
}: {
  headingText: string;
  headingDescription?: string;
  navItems: NavItemProp[];
  bottomPopoverItems: NavItemProp[];
  children: React.ReactNode;
  email: string;
  sendPasswordResetEmailActionPath: string;
  logoutActionPath: string;
}) {
  return (
    <Flex style={{ height: "100vh" }}>
      <Nav
        headingText={headingText}
        headingDescription={headingDescription}
        navItems={navItems}
        bottomPopoverItems={bottomPopoverItems}
        email={email}
        sendPasswordResetEmailActionPath={sendPasswordResetEmailActionPath}
        logoutActionPath={logoutActionPath}
      />
      <main className="h-screen w-full bg-[var(--background-color-secondary)] overflow-auto pl-[var(--space-2)] md:pl-4 pr-4 md:pr-4 pt-4 pb-4">
        {children}
      </main>
    </Flex>
  );
}

export function CenterContentLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Flex style={{ height: "100vh" }} direction="column">
      <header className="flex flex-row w-full h-[80px] bg-gray-300">
        <div style={{ height: "80px" }} className="ml-6">
          <img
            src={logo}
            height="64"
            alt="かかりつけフィットネス"
            style={{
              height: "64px",
              width: "100%",
              objectFit: "cover",
              marginTop: "8px",
            }}
          />
        </div>
      </header>
      <main className="h-screen w-full bg-[var(--background-color-secondary)] overflow-auto pl-[var(--space-2)] md:pl-4 pr-4 md:pr-4 pt-10 pb-4">
        <Container width="440px" align="center">
          {children}
        </Container>
      </main>
    </Flex>
  );
}
